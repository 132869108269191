import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CarecrewLogo from "../assets/carecrew_og";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { label: "Products", link: "/products" },
    { label: "Solutions", link: "/solutions" },
    { label: "About", link: "/about" },
    { label: "Resources", link: "/resources" },
    { label: "Pricing", link: "/pricing" },
  ];

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleBookDemo = () => {
    navigate("/bookdemo");
  };

  return (
    <header className="w-full bg-white shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between px-5 sm:px-10 lg:px-20 py-4">
        {/* Logo Section */}
        <Link to="/" className="flex items-center">
          <CarecrewLogo size={13} />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex gap-8">
          {navItems.map((item) => (
            <Link
              key={item.label}
              to={item.link}
              className="text-gray-600 hover:text-secondary transition duration-300 py-2"
            >
              {item.label}
            </Link>
          ))}
        </nav>

        {/* Action Buttons */}
        <div className="hidden md:flex items-center gap-4">
          <button
            onClick={handleBookDemo}
            className="text-primary border-[2px] border-primary hover:bg-primary hover:text-white px-6 py-[8px] rounded-full text-sm transition duration-300"
          >
            Book a Demo
          </button>
          <button
            onClick={handleSignUp}
            className="bg-primary text-white px-6 py-[10px] rounded-full text-sm hover:bg-secondary transition duration-300"
          >
            Sign up for free!
          </button>
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-gray-600 hover:text-blue-600 focus:outline-none"
          aria-label="Toggle Menu"
          aria-expanded={isMenuOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="md:hidden bg-white shadow-md">
          {navItems.map((item) => (
            <Link
              key={item.label}
              to={item.link}
              className="block px-4 py-3 text-gray-600 hover:bg-gray-50 border-b border-gray-200"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
          <div className="p-4 space-y-3">
            <button
              onClick={handleBookDemo}
              className="w-full text-primary border-2 border-primary hover:bg-primary hover:text-white px-6 py-2 rounded-full text-sm transition duration-300"
            >
              Book a Demo
            </button>
            <button
              onClick={handleSignUp}
              className="w-full bg-primary text-white px-6 py-2 rounded-full text-sm hover:bg-secondary transition duration-300"
            >
              Sign up for free!
            </button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;

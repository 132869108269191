import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const managerDetails = [
  {
    title: "Save Time and Reduce Errors",
    content:
      "By automating shift assignments and providing real-time schedule updates, our platform helps managers cut down on manual work and minimizes the risk of scheduling mistakes. This results in more efficient planning and allows you to focus on strategic decision-making.",
    image: "/images/manager-benefit-1.jpg", // Replace with your image path
  },
  {
    title: "Streamline Workforce Planning",
    content:
      "Our advanced analytics and reporting tools give you actionable insights into labor costs, overtime trends, and staff availability. With this data at your fingertips, you can optimize shift allocation, balance workloads, and plan for peak business hours with confidence.",
    image: "/images/manager-benefit-2.jpg", // Replace with your image path
  },
  {
    title: "Enhance Team Communication",
    content:
      "Effective communication is crucial for smooth operations. Our platform enables you to broadcast messages, share updates, and coordinate quickly with your team. This ensures that all scheduling changes are immediately communicated, reducing misunderstandings and delays.",
    image: "/images/manager-benefit-3.jpg", // Replace with your image path
  },
];

const employeeDetails = [
  {
    title: "Easy Access to Your Schedule",
    content:
      "Employees can effortlessly view their schedules from any device, ensuring they are always informed about their shifts. With an intuitive, mobile-first design, accessing shift details and updates is simple and convenient.",
    image: "/images/employee-benefit-1.jpg", // Replace with your image path
  },
  {
    title: "Flexible Shift Requests",
    content:
      "Our solution empowers employees to request shift swaps, changes, or time off with just a few clicks. This flexibility not only improves work-life balance but also helps managers fill gaps quickly, ensuring that no shift goes unstaffed.",
    image: "/images/employee-benefit-2.jpg", // Replace with your image path
  },
  {
    title: "Stay Updated in Real-Time",
    content:
      "Receive instant alerts on shift changes, open shifts, or last-minute adjustments through push notifications, SMS, or in-app messaging. This real-time communication ensures employees never miss critical updates, allowing them to plan their day effectively.",
    image: "/images/employee-benefit-3.jpg", // Replace with your image path
  },
];

const ExpandedBenefitsSection = () => {
  return (
    <div className="mt-12">
      {/* Managers Section */}
      <section className="mb-16">
        <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
          Benefits for Managers
        </h2>
        {managerDetails.map((item, idx) => (
          <section
            key={`manager-${idx}`}
            className={`flex flex-col md:flex-row items-center mb-12 ${
              idx % 2 === 0 ? "" : "md:flex-row-reverse"
            }`}
          >
            <div className="md:w-1/2 p-4">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                {item.title}
              </h3>
              <p className="text-gray-600 text-lg">{item.content}</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img
                src={item.image}
                alt={item.title}
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
          </section>
        ))}
      </section>

      {/* Employees Section */}
      <section className="mb-16">
        <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
          Benefits for Employees
        </h2>
        {employeeDetails.map((item, idx) => (
          <section
            key={`employee-${idx}`}
            className={`flex flex-col md:flex-row items-center mb-12 ${
              idx % 2 === 0 ? "md:flex-row-reverse" : ""
            }`}
          >
            <div className="md:w-1/2 p-4">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                {item.title}
              </h3>
              <p className="text-gray-600 text-lg">{item.content}</p>
            </div>
            <div className="md:w-1/2 p-4">
              <img
                src={item.image}
                alt={item.title}
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
          </section>
        ))}
      </section>
    </div>
  );
};

const ProductsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Advanced Shift Management Software | CareCrew</title>
        <meta
          name="description"
          content="Discover CareCrew – an advanced shift management software designed for efficient scheduling, real-time notifications, and robust analytics. Ideal for managers and employees across various industries."
        />
        <link rel="canonical" href="https://carecrew.co/products" />
      </Helmet>
      <Header />
      <main className="container mx-auto px-4 py-12">
        {/* Hero Section */}
        <section className="text-center mb-16">
          <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 mb-4">
            Advanced Shift Management for Modern Workforces
          </h1>
          <p className="text-xl text-gray-700 max-w-2xl mx-auto">
            Optimize scheduling, streamline communication, and boost efficiency
            with CareCrew – the ultimate solution for shift management.
          </p>
        </section>

        {/* Detailed Feature Overview */}
        <section className="mb-16">
          {/* <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
            Detailed Feature Overview
          </h2> */}
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {/* Insert your feature cards here */}
          </div>
        </section>

        {/* Expanded Benefits Section */}
        <ExpandedBenefitsSection />

        {/* Industry Examples */}
        <section className="mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
            Industry-Agnostic Examples
          </h2>
          <p className="text-gray-600 text-center mb-6">
            Our solution works seamlessly across various industries:
          </p>
          <ul className="list-disc list-inside text-gray-600 max-w-xl mx-auto space-y-2">
            <li>Retail: Manage store clerk scheduling efficiently.</li>
            <li>Restaurants: Ensure optimal coverage during busy hours.</li>
            <li>Hotels: Streamline housekeeping and concierge planning.</li>
            <li>Healthcare: Simplify medical facility shift coordination.</li>
          </ul>
        </section>

        {/* Visual Demo / Screenshots Section */}
        <section className="mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
            Visual Demo & Screenshots
          </h2>
          <p className="text-gray-600 text-center mb-6">
            Explore our interactive demo and annotated screenshots to see
            CareCrew in action.
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
            <img
              src="/images/demo-1.jpg"
              alt="Demo screenshot 1"
              className="w-full md:w-1/2 rounded-lg shadow-lg"
            />
            <img
              src="/images/demo-2.jpg"
              alt="Demo screenshot 2"
              className="w-full md:w-1/2 rounded-lg shadow-lg"
            />
          </div>
        </section>

        {/* Additional SEO Content */}
        <section className="mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
            Why Choose CareCrew?
          </h2>
          <div className="prose lg:prose-xl max-w-4xl mx-auto text-gray-800">
            <p>
              CareCrew empowers your business with advanced tools that
              streamline shift management and enhance workforce productivity.
              Our platform integrates dynamic scheduling, real-time
              notifications, and robust analytics, ensuring your team is always
              where they need to be.
            </p>
            <p>
              Built on modern React architecture, CareCrew delivers a seamless
              user experience across devices. With extensive SEO best practices,
              our solution not only optimizes internal operations but also
              boosts your online visibility.
            </p>
            <ul>
              <li>
                <strong>Efficiency:</strong> Reduce administrative overhead with
                automated scheduling.
              </li>
              <li>
                <strong>Flexibility:</strong> Empower employees with
                self-service scheduling.
              </li>
              <li>
                <strong>Insight:</strong> Leverage detailed analytics for
                data-driven decisions.
              </li>
              <li>
                <strong>Scalability:</strong> Our solution grows with your
                business.
              </li>
              <li>
                <strong>SEO Optimized:</strong> Enhance organic traffic and
                online presence.
              </li>
            </ul>
            <p>
              Experience the future of shift management with CareCrew – where
              technology meets efficiency. Get started today and transform your
              workforce planning.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ProductsPage;
